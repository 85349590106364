import React, { useState } from 'react';
import {
  Button,
  Drawer,
  Box,
  Typography,
  TextField,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { register } from '../redux/UserSlice';
import { createUser } from '../api/UserApi';


const SignUp = () => {

  let dispatch = useDispatch();

  const queryClient = useQueryClient();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();


  const toggleDrawer = (state) => {
    setOpen(state);
  };

  let createUserNytation = useMutation(createUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('register');
      dispatch(register(data))
      data.role === "Admin" ? navigate('/admin/dashboard') : navigate('/');
      enqueueSnackbar("Registation successfully", { variant: "success" })
    },
    onError: (error) => {
      enqueueSnackbar("Invalid credentials. Please try again.", { variant: "error" });
      // setLoading(false);
    }
  })


  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target)
    let reqUserData = Object.fromEntries(formData)

    createUserNytation.mutate(reqUserData)
    console.log("FORM DTA", reqUserData);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          toggleDrawer(false);
          navigate('/');
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: '400px',
            padding: 3,
            background: 'linear-gradient(to right, #ffffff, #f3f4f6)',
            borderRadius: '10px 0 0 10px',
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
            Create Account
          </Typography>
          <IconButton onClick={() => toggleDrawer(false)} sx={{ color: '#333' }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form onSubmit={(e) => handleSubmit(e)}>
          <TextField
            label="Full Name"
            variant="outlined"
            fullWidth
            type="text"
            name="name"
            required
            sx={{ mb: 2 }}
          />


          <TextField
            label="Mobile Number"
            variant="outlined"
            fullWidth
            type="tel"
            name="mobile"
            required
            sx={{ mb: 2 }} />


          <TextField
            label="Email Address"
            type="email"
            name="email"
            variant="outlined"
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Password"
            type="password"
            name='password'
            variant="outlined"
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Confirm Password"
            type="password"
            name='confirmpassword'
            variant="outlined"
            fullWidth
            required
            sx={{ mb: 3 }}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              background: 'linear-gradient(to right, #6a11cb, #2575fc)',
              color: '#fff',
              fontWeight: 'bold',
              padding: '10px 0',
              '&:hover': {
                background: 'linear-gradient(to right, #2575fc, #6a11cb)',
              },
            }}
          >
            Signup
          </Button>
        </form>

        <Box mt={2} textAlign="center">
          <Typography variant="body2" sx={{ color: '#555' }}>
            Already have an account?{' '}
            <span
              onClick={() => navigate('/login')}
              style={{
                color: '#2575fc',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              Login
            </span>
          </Typography>
        </Box>
      </Drawer>
    </>
  );
};

export default SignUp;
