import React from 'react';
import { Box, Typography, Container, Grid, Card, CardContent, CardMedia, Divider } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import VerifiedIcon from '@mui/icons-material/Verified';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PeopleIcon from '@mui/icons-material/People';
import PagesIcon from '@mui/icons-material/Pages';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CoffeeIcon from '@mui/icons-material/Coffee';
import book from "../assets/books.jpg";
import person from "../assets/p1.jpeg";


const About = () => {
    return (
        <Box sx={{ bgcolor: '#f4f6f8', py: 5 }}>
            <Container>
                <Typography variant="h3" align="center" gutterBottom>
                    About Us
                </Typography>
                <Card sx={{ mb: 5, p: 2 }}>
                    <CardMedia
                        component="img"
                        height="300"
                        image={book}
                        alt="About Us Image"
                    />
                    <CardContent>
                        <Typography variant="h5" align="center" gutterBottom>
                            Welcome to Bookworm
                        </Typography>
                        <Typography variant="body1" align="center" fontStyle="italic">
                            “Many desktop publishing packages and web page editors now use Lorem Ipsum as their default
                            model search for evolved over sometimes by accident, sometimes on purpose.”
                        </Typography>
                    </CardContent>
                </Card>

                <Box>
                    <Typography variant="h4" gutterBottom>
                        What we really do?
                    </Typography>
                    <Typography paragraph>
                        Mauris tempus erat laoreet turpis lobortis, eu tincidunt erat fermentum. Aliquam non tincidunt urna. Integer
                        tincidunt nec nisl vitae ullamcorper. Proin sed ultrices erat. Praesent varius ultrices massa at faucibus.
                        Aenean dignissim, orci sed faucibus pharetra, dui mi dignissim tortor, sit amet condimentum mi ligula sit
                        amet augue.
                    </Typography>
                </Box>

                <Grid container spacing={4} sx={{ mt: 5 }}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ p: 3, height: '100%' }}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Our Vision
                                </Typography>
                                <Typography>
                                    Pellentesque sodales augue eget ultricies ultricies. Curabitur sagittis ultrices condimentum.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ p: 3, height: '100%' }}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Our Mission
                                </Typography>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis velit lectus, posuere a blandit
                                    metus mauris, tristique quis sapien eu, rutrum vulputate enim.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Divider sx={{ my: 4 }} />

                <Grid container spacing={4} sx={{ textAlign: 'center' }}>
                    <Grid item xs={6} sm={3}>
                        <PeopleIcon fontSize="large" color="primary" />
                        <Typography variant="h4">45M</Typography>
                        <Typography variant="subtitle1">Active Readers</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <PagesIcon fontSize="large" color="primary" />
                        <Typography variant="h4">+6k</Typography>
                        <Typography variant="subtitle1">Total Pages</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <ShoppingCartIcon fontSize="large" color="primary" />
                        <Typography variant="h4">30.6M</Typography>
                        <Typography variant="subtitle1">Buyers Active</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <CoffeeIcon fontSize="large" color="primary" />
                        <Typography variant="h4">283</Typography>
                        <Typography variant="subtitle1">Cups of Coffee</Typography>
                    </Grid>
                </Grid>

                <Divider sx={{ my: 4 }} />

                <Typography variant="h4" gutterBottom>
                    Why Choose Us
                </Typography>

                <Grid container spacing={4} sx={{ mt: 2 }}>
                    {[
                        { icon: <LocalShippingIcon fontSize="large" color="primary" />, title: "Free Delivery", desc: "Reliable and fast delivery options." },
                        { icon: <CreditCardIcon fontSize="large" color="primary" />, title: "Secure Payment", desc: "Safe and secure transactions." },
                        { icon: <VerifiedIcon fontSize="large" color="primary" />, title: "Money Back Guarantee", desc: "Customer satisfaction guaranteed." },
                        { icon: <SupportAgentIcon fontSize="large" color="primary" />, title: "24/7 Support", desc: "Round-the-clock assistance." },
                    ].map((feature, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card sx={{ textAlign: 'center', p: 3, height: '100%' }}>
                                {feature.icon}
                                <Typography variant="h6" gutterBottom>
                                    {feature.title}
                                </Typography>
                                <Typography variant="body2">{feature.desc}</Typography>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Divider sx={{ my: 4 }} />

                <Typography variant="h4" gutterBottom>
                    Our Team
                </Typography>
                <Grid container spacing={4} sx={{ mt: 2 }}>
                    {[
                        { name: 'Ashish Bendale', role: 'Client Care', image: person },
                        { name: 'Harshal Mahajan', role: 'CEO/Founder', image: person },
                        { name: 'Sejal Nehete', role: 'Support Boss', image: person },
                        { name: 'abcd xyz', role: 'Delivery Driver', image: person },
                    ].map((member, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card>
                                <CardMedia component="img" height="200" image={member.image} alt={member.name} />
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {member.name}
                                    </Typography>
                                    <Typography color="text.secondary">{member.role}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default About;
