import React from 'react';
import { Box, Grid, Typography, TextField, Button, FormControlLabel, Radio, RadioGroup, Divider, Link, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addOder } from '../api/OrdersApi';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { clearCart } from '../redux/CartSlice';


const Checkout = () => {

    let dispatcher = useDispatch()
    let { items, cartTotal } = useSelector((state) => state.cart)
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { userData } = useSelector((state) => state.user);


    const handleApplyCoupon = () => {
        console.log("Coupon applied");
    };

    const loginMutation = useMutation(addOder, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('Login');
            enqueueSnackbar("Order Placed", { variant: "success" })
            dispatcher(clearCart())
        },
        onError: (error) => {
            enqueueSnackbar("Order Place Fail!", { variant: "error" });
            // setLoading(false);
        }
    })

    let doCheckOut = async () => {
        let finalItems = []

        items.map((book) => {
            finalItems.push({
                bookid: book._id,
                quantity: book.quantity
            })
        })

        let orderData = {
            userId: userData.userId,
            orderItems: finalItems,
            total: Number(cartTotal),
            orderNote: "Please deliver after 5 PM",
            paymentDetails: {
                paymentMethod: "Credit Card",
                transactionId: "TXN123456789",
                paymentStatus: "Paid"
            },
        }
        await loginMutation.mutate(orderData)
    }

    return (
        <Box
            sx={{
                padding: '4rem',
                backgroundColor: '#ffe6e6',
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box sx={{ maxWidth: '1200px', width: '100%' }}>
                <Grid container spacing={4} alignItems="stretch">
                    <Grid item xs={12} md={7}>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                border: '1px solid #ccc',
                                padding: 3,
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h5" sx={{ mb: 3 }}>
                                Billing Details
                            </Typography>
                            <TextField fullWidth label="First Name *" variant="outlined" sx={{ mb: 2 }} />
                            <TextField fullWidth label="Last Name *" variant="outlined" sx={{ mb: 2 }} />
                            <TextField fullWidth label="Company Name" variant="outlined" sx={{ mb: 2 }} />
                            <TextField fullWidth label="Country *" variant="outlined" defaultValue="United Kingdom (UK)" sx={{ mb: 2 }} />
                            <TextField fullWidth label="Street Address *" variant="outlined" sx={{ mb: 2 }} />
                            <TextField fullWidth label="Apartment, suite, unit, etc. (optional)" variant="outlined" sx={{ mb: 2 }} />
                            <TextField fullWidth label="Town / City *" variant="outlined" sx={{ mb: 2 }} />
                            <TextField fullWidth label="County" variant="outlined" sx={{ mb: 2 }} />
                            <TextField fullWidth label="Postcode *" variant="outlined" sx={{ mb: 2 }} />
                            <TextField fullWidth label="Phone *" variant="outlined" sx={{ mb: 2 }} />
                            <TextField fullWidth label="Email Address *" variant="outlined" sx={{ mb: 2 }} />
                            <TextField
                                fullWidth
                                multiline
                                minRows={4}
                                label="Order Notes (optional)"
                                variant="outlined"
                                placeholder="Notes about your order, e.g., special notes for delivery."
                                sx={{ mb: 2 }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Box
                            sx={{

                                backgroundColor: 'white',
                                border: '1px solid black',
                                padding: 3,
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Box>
                                <Typography variant="h5" sx={{ mb: 2 }}>
                                    Your Order
                                </Typography>
                                {items.map((book) => {
                                    return (
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                            <Typography>{book.title} X {book.quantity}</Typography>
                                            <Typography>₹ {book.subTotal}</Typography>
                                        </Box>
                                    )
                                })}

                                <Divider sx={{ my: 2 }} />
                                {/* <Box>
                                    <Typography variant="h6" sx={{ mb: 2 }}>
                                        Shipping
                                    </Typography>
                                    <Typography sx={{ mb: 1 }}>Free Shipping</Typography>
                                    <Typography sx={{ mb: 1 }}>Flat rate: $15</Typography>
                                    <Typography sx={{ mb: 2 }}>Local pickup: $8</Typography>
                                    <Typography variant="body2" sx={{ color: '#1976d2', cursor: 'pointer' }}>
                                        Shipping to Turkey. <Link underline="hover">Change Address</Link>
                                    </Typography>
                                </Box>
                                <Divider sx={{ my: 2 }} />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography>Subtotal</Typography>
                                    <Typography>£97.99</Typography>
                                </Box> */}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                    <Typography variant="h6">Total</Typography>
                                    <Typography variant="h6">₹ {cartTotal}</Typography>
                                </Box>
                            </Box>

                            <TextField
                                label="Coupon code"
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                color="primary"
                                                onClick={handleApplyCoupon}
                                                sx={{ color: 'black', textTransform: 'none' }}
                                            >
                                                Apply Coupon
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ mb: 3 }}
                            />

                            <Box>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    Payment
                                </Typography>
                                <RadioGroup defaultValue="direct_bank_transfer">
                                    <FormControlLabel
                                        value="direct_bank_transfer"
                                        control={<Radio />}
                                        label="Direct Bank Transfer"
                                    />
                                    <Typography variant="body2" sx={{ ml: 4, mb: 2 }}>
                                        Make your payment directly into our bank account. Please use your Order ID as the
                                        payment reference. Your order won’t be shipped until the funds have cleared in
                                        our account.
                                    </Typography>
                                    <FormControlLabel
                                        value="check_payments"
                                        control={<Radio />}
                                        label="Check Payments"
                                    />
                                    <Typography variant="body2" sx={{ ml: 4, mb: 2 }}>
                                        Please send a check to Store Name, Store Street, Store Town, Store State / County,
                                        Store Postcode.
                                    </Typography>
                                    <FormControlLabel
                                        value="cash_on_delivery"
                                        control={<Radio />}
                                        label="Cash on Delivery"
                                    />
                                    <Typography variant="body2" sx={{ ml: 4 }}>
                                        Pay with cash upon delivery.
                                    </Typography>
                                </RadioGroup>
                            </Box>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => doCheckOut()}
                                sx={{ mt: 3, backgroundColor: 'black', textTransform: 'none' }}
                            >
                                Place Order
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Checkout;
