import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  totalQuantity: 0,
  cartTotal: 0,
};

const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      let id = action.payload._id
      let quantity = action.payload.quantity

      const existingItem = state.items.find((item) => item._id === id);
      if (existingItem) {
        existingItem.quantity += quantity;
        existingItem.subTotal += existingItem.price * quantity;
      } else {
        state.items = [...state.items, action.payload]
      }
    },

    removeItem: (state, action) => {
      const { id } = action.payload;
      state.items = state.items.filter((book) => book._id !== id)
    },

    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload;

      const existingItem = state.items.find((item) => item.id === id);
      if (existingItem) {
        const difference = quantity - existingItem.quantity;

        existingItem.quantity = quantity;
        existingItem.totalPrice = existingItem.price * quantity;

        state.totalQuantity += difference;
        state.totalPrice += difference * existingItem.price;
      }
    },

    calculatetotal: (state) => {
      let total = 0
      state.items.forEach((item) => {
        total += item.price * item.quantity
      })
      state.cartTotal = total
    },

    clearCart: (state) => {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
    },
  },
});

export const { addItem, calculatetotal, removeItem, updateQuantity, clearCart } = CartSlice.actions;

export default CartSlice.reducer;
