import React, { useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Button, IconButton, TextField, Box, Divider, InputAdornment } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { calculatetotal, removeItem, updateQuantity } from '../redux/CartSlice';
import { useNavigate } from 'react-router-dom';
import image from '../assets/Frame.png'

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { items, cartTotal } = useSelector((state) => state.cart)

  const shippingOptions = { free: 0, flat: 15, local: 8 };
  const selectedShipping = shippingOptions.flat;
  // const total = subtotal + selectedShipping;

  const handleQuantityChange = (id, quantity) => {
    if (quantity < 1) return;
    dispatch(updateQuantity({ id, quantity }));
  };

  const handleRemoveItem = (id) => {
    dispatch(removeItem({ id }));
  };

  const handleApplyCoupon = () => {
    alert('Coupon applied!');
  };

  dispatch(calculatetotal())
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>Shopping Cart</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {items.map((item) => (
              <Grid item xs={12} key={item.id}>
                <Card sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
                  <CardMedia
                    component="img"
                    image={item.image || image}
                    alt={item.name}
                    sx={{ width: 100, height: 100, objectFit: 'cover', mr: 2 }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">{item.title}</Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      Price: ₹ {item.price}
                    </Typography>

                    <Box display="flex" alignItems="center">
                      <Typography variant="body1">Quantity:</Typography>
                      <TextField
                        type="number"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(item.id, +e.target.value)}
                        size="small"
                        sx={{ ml: 1, width: 60 }}
                        inputProps={{ min: 1 }}
                      />
                    </Box>
                  </CardContent>
                  <IconButton onClick={() => handleRemoveItem(item._id)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ p: 3, border: '1px solid black', borderRadius: 1 }}>
            <Typography variant="h5" gutterBottom>Cart Totals</Typography>

            <Box display="flex" justifyContent="space-between">
              <Typography>Subtotal</Typography>
              <Typography>₹ {cartTotal}</Typography>
            </Box>

            <Box display="flex" justifyContent="space-between" mt={2}>
              <Typography>Shipping</Typography>
              <Typography>Free Shipping</Typography>
            </Box>

            {/* <Box display="flex" justifyContent="space-between">
              <Typography>Flat rate:</Typography>
              <Typography>${shippingOptions.flat}</Typography>
            </Box> */}

            <Box display="flex" justifyContent="space-between">
              <Typography>Local pickup:</Typography>
              <Typography>${shippingOptions.local}</Typography>
            </Box>
            <Typography variant="body2" color="text.secondary" mt={1}>
              Shipping to Mumbai. <Button variant="text" size="small">Change Address</Button>
            </Typography>

            <Divider sx={{ my: 2 }} />

            <TextField
              label="Coupon code"
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button color="primary" onClick={handleApplyCoupon} sx={{ color: 'black' }}>Apply Coupon</Button>
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
            />

            <Box display="flex" justifyContent="space-between" mt={2} fontWeight="bold">
              <Typography variant="h6">Total</Typography>
              <Typography variant="h6">₹ {cartTotal}</Typography>
            </Box>

            <Button variant="contained" sx={{ mt: 2, backgroundColor: 'black', color: 'white' }} fullWidth
              onClick={() => navigate('/checkout')}
            >
              Proceed to Checkout
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Cart;
