import React, { useState } from 'react'
import { Typography, Button, Grid, Card, CardMedia, CardContent, CardActions, Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetchOrdersByCustId } from '../api/OrdersApi'
import { useSnackbar } from 'notistack'
import { formateDate } from '../utilities'
import { useSelector } from 'react-redux'


const OrderTracking = () => {

    let { userData } = useSelector((state) => state.user)
    const [ordersData, setordersData] = useState([])
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate()

    const { enqueueSnackbar } = useSnackbar();


    const { data: orderData, isLoading, isError } = useQuery(['books', {
        custid: userData.userId
    }], fetchOrdersByCustId, {
        onSuccess: (data) => {
            setordersData(data);
            console.log("DATA", data);
        },
        onError: (error) => { }
    });

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', color: '#1e88e5', marginBottom: 4 }}>
                Track Orders
            </Typography>
            <Grid container spacing={4}>
                {ordersData.map((order) => (
                    <Grid item xs={12} sm={6} md={4} key={order._id}>
                        <Card
                            sx={{
                                maxWidth: 345,
                                cursor: 'pointer',
                                transition: 'transform 0.3s',
                                '&:hover': { transform: 'scale(1.05)' },
                            }}
                            onClick={() => navigate(`/orderdetails`, { state: { ...order } })}
                        >
                            <CardContent>
                                <Typography gutterBottom variant="h5">
                                    {order.orderItems.length} Books
                                </Typography>

                                <Typography variant="body2" color="text.secondary">
                                    On: {formateDate(order.orderDate)}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Status: {order.status}
                                </Typography>
                                <Typography variant="h6" color="primary" sx={{ marginTop: 1 }}>
                                    Total: ₹ {order.total}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" sx={{ color: '#1e88e5' }} onClick={() => navigate(`/bookdetails`)}>
                                    View Details
                                </Button>

                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}

export default OrderTracking