import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';


const ErrorPage = () => {
    const navigate = useNavigate()

    const goBack = () => {
        navigate(-1)
    }

    return (
        <Box
            id="content"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center',
                flexDirection: 'column',
                padding: '0 16px'
            }}
        >
            <Typography
                variant="h1"
                sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '170px', sm: '200px' },
                    lineHeight: '1',
                    mt: { xs: 1, xl: 3 },
                    animation: 'smoothBounce 2s infinite ease-out',
                    '@keyframes smoothBounce': {
                        '0%, 20%, 50%, 80%, 100%': {
                            transform: 'translateY(0)',
                        },
                        '40%': {
                            transform: 'translateY(-20px)',
                        },
                        '60%': {
                            transform: 'translateY(-10px)',
                        }
                    }
                }}
            >
                404
            </Typography>
            <Typography
                variant="h6"
                sx={{ fontSize: '24px', fontWeight: 'medium' }}
            >
                Woops, looks like this page does not exist
            </Typography>
            <Typography
                variant="body1"
                sx={{ fontSize: '16px', mb: 2 }}
            >
                You could either go back or go to homepage
            </Typography>
            <Box>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<KeyboardBackspaceTwoToneIcon />}
                    sx={{
                        height: '40px',
                        width: '150px',
                        fontWeight: 'medium',
                        borderRadius: 0,
                        '&:hover': {
                            backgroundColor: 'primary.main',
                            color: '#fff',
                            borderRadius: 0,

                        }
                    }}
                    onClick={goBack}
                >
                    Go Back
                </Button>
            </Box>
        </Box>
    )
}

export default ErrorPage
