import { getInstance } from ".";

export let createUser = async (data) => {
    
    try {
        let result = await getInstance().post('/adduser', data)
        return result.data
    } catch (error) {
        console.error("registration error", error.response || error.message);
        throw new Error(error.response?.data?.message || error.message);
    }
}

export const userLogin = async (data) => {
    try {
        const response = await getInstance().post("login", data);
        localStorage.setItem("userData", JSON.stringify(response.data));
        return response.data;
    } catch (error) {
        console.error("login error", error.response || error.message);
        throw new Error(error.response?.data?.message || error.message);
    }
};

export const userSignup = async (data) => {
    try {
        const response = await getInstance().post("adduser", data);
        return response.data;
    } catch (error) {
        console.error("Signup error", error.response || error.message);
        throw new Error(error.response?.data?.message || error.message);
    }
};


export const userLogout = async () => {
    try {
        const response = await getInstance().post("logout");
        return response.data;
    } catch (error) {
        console.error("Logout error:", error.response || error.message);
        throw new Error(error.response?.data?.message || error.message);
    }
};