import { Box, Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'

const AdminOrderDetails = () => {
  let location = useLocation().state

  return (
    <Box
      sx={{
        padding: '4rem',
        backgroundColor: '#ffe6e6',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ maxWidth: '1200px', width: '100%' }}>
        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                backgroundColor: 'white',
                border: '1px solid #ccc',
                padding: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h5" sx={{ mb: 2 }}>
                Order Items
              </Typography>
              {location.orderItems.map((book) => {
                return (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography>{book.bookid.title} X {book.quantity}</Typography>
                  </Box>
                )
              })}


              <Typography variant="h5" sx={{ mb: 2 }}>
                Shipping Details
              </Typography>
              <Typography>Customer Name: {location.userId.name}</Typography>
              <Typography>Customer Mobile: {location.userId.mobile}</Typography>
              <Typography>Customer Email: {location.userId.email}</Typography>
              <Divider />
              <Typography>Address:{location.userId.shippingAddress.address},{location.userId.shippingAddress.city}, {location.userId.shippingAddress.district}, {location.userId.shippingAddress.state}, {location.userId.shippingAddress.pincode} </Typography>

              <Typography variant="h6" sx={{ mb: 2 }}>
                Change Status
              </Typography>
              <RadioGroup
                sx={{
                  display: "flex",
                  flexDirection: "row"
                }}
                defaultValue="Approved">
                <FormControlLabel
                  value="Approved"
                  control={<Radio />}
                  label="Approved" />

                <FormControlLabel
                  value="Dispatch"
                  control={<Radio />}
                  label="Dispatch" />

                <FormControlLabel
                  value="Delivered"
                  control={<Radio />}
                  label="Delivered" />
              </RadioGroup>
              <Button
                variant="contained"
                onClick={() => { }}
                sx={{ backgroundColor: 'black', textTransform: 'none' }}
              >
                Update
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <Box
              sx={{

                backgroundColor: 'white',
                border: '1px solid black',
                padding: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h5" sx={{ mb: 2 }}>
                Status
              </Typography>
              <Typography>Current Status: {location.status}</Typography>
              <Typography>Order Date: {location.orderDate}</Typography>
              <Typography>Total Amount: {location.total}</Typography>
              <Typography>Order Note: {location.orderNote}</Typography>

              <Divider />

              <Typography variant="h5" sx={{ mb: 2 }}>
                Payment Details
              </Typography>
              <Typography>Payment Method: {location.paymentDetails.paymentMethod}</Typography>
              <Typography>Transaction ID: {location.paymentDetails.transactionId}</Typography>
              <Typography>Payment Status: {location.paymentDetails.paymentStatus}</Typography>
              <Typography>Date: {location.paymentDetails.paymentDate}</Typography>


            </Box>


          </Grid>
        </Grid>
      </Box>
    </Box >
  )
}

export default AdminOrderDetails