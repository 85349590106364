import React from 'react';
import { Container, Typography, Grid, Box, Divider, CircularProgress, Alert,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import bookimg from '../../assets/bookcover.jpg';
import { fetchBookById } from '../../api/BooksApi';

const AdBookDetails = () => {
  const location = useLocation();
  const id = location.state.id; 

  const { data: book, isLoading, isError } = useQuery(['book', id], () => fetchBookById(id));

  if (isLoading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (isError) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert severity="error">Failed to load book details. Please try again later.</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Box component="img" src={book.coverImage || bookimg} alt="Book Cover" sx={{ width: '100%', borderRadius: 1 }} />
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="h4" gutterBottom>{book.title}</Typography>
          <Typography variant="subtitle1" color="text.secondary">By {book.author}</Typography>

          {book.category && (
            <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
              Category: {book.category}
            </Typography>
          )}

          {book.price && (
            <Typography variant="h5" sx={{ mt: 2 }}>
              Price: ₹{book.price}
            </Typography>
          )}

          <Typography variant="body1" sx={{ mt: 2 }}>
            {book.description || 'No description available for this book.'}
          </Typography>

          {book.publisher && (
            <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
              Publisher: {book.publisher}
            </Typography>
          )}

          {book.publishDate && (
            <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
              Published on: {new Date(book.publishDate).toLocaleDateString()}
            </Typography>
          )}

          {book.language && (
            <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
              Language: {book.language}
            </Typography>
          )}

          {book.pages && (
            <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
              Pages: {book.pages}
            </Typography>
          )}

          <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
            ISBN: {book.ISBN}
          </Typography>

          {book.stockQuantity !== undefined && (
            <Typography variant="body2" sx={{ mt: 1, color: book.stockQuantity > 0 ? 'green' : 'red' }}>
              Stock: {book.stockQuantity > 0 ? `${book.stockQuantity} available` : 'Out of stock'}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" sx={{ color: 'gray' }}>
          Created At: {new Date(book.createdAt).toLocaleString()}
        </Typography>
        <Typography variant="body2" sx={{ color: 'gray' }}>
          Last Updated: {new Date(book.updatedAt).toLocaleString()}
        </Typography>
      </Box>
    </Container>
  );
};

export default AdBookDetails;
