import React, { useState, useEffect } from 'react'
import { Container, Box, Typography, TextField, Button, Grid2, Avatar, FormControlLabel, Checkbox, Divider, IconButton, InputAdornment, CircularProgress } from '@mui/material'
import EastTwoToneIcon from '@mui/icons-material/EastTwoTone';
import { VisibilityOff, Visibility, Password } from '@mui/icons-material'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from "react-router-dom"
import { useSnackbar } from "notistack"
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../redux/UserSlice'
import { userLogin, userLogout } from '../api/UserApi';


const Login = () => {

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.user);
    const { enqueueSnackbar } = useSnackbar();
    const [loginData, setLoginData] = useState({ email: "", password: "" });
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword((show) => !show);
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
        const savedLoginData = JSON.parse(localStorage.getItem('loginData'));
        if (savedLoginData) {
            setLoginData(savedLoginData);
            setRememberMe(true);
        }
    }, []);

    const handleRememberMe = () => {
        setRememberMe(!rememberMe)
    }




    const loginMutation = useMutation(userLogin, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('Login');
            dispatch(login(data));

            if (rememberMe) {
                localStorage.setItem('loginData', JSON.stringify(loginData));
            } else {
                localStorage.removeItem('loginData');
            }
            data.role === "Admin" ? navigate('/admin/dashboard') : navigate('/');
            enqueueSnackbar("Login successfully", { variant: "success" })
        },
        onError: (error) => {
            enqueueSnackbar("Invalid credentials. Please try again.", { variant: "error" });
            setLoading(false);
        }
    })


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!loginMutation.isLoading) {
            await loginMutation.mutate(loginData)
        }
    }



    return (
        <Container maxWidth="xl" sx={{ width: '100%', height: "100vh" }}>
            <Grid2 container spacing={2} sx={{ px: 10 }}>
                <Grid2 size={7} sx={{ position: 'relative', height: "70vh", display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'left' }}>
                    {/* Background text */}
                    <Typography
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: 200,
                            fontWeight: 700,
                            color: '#ccc',
                            zIndex: 0,
                            pointerEvents: 'none',
                            userSelect: 'none'
                        }}
                    >
                        Prime
                    </Typography>

                    {/* Foreground content */}
                    <Typography sx={{
                        fontSize: 50,
                        fontWeight: 600,
                        lineHeight: '63px',
                        zIndex: 1
                    }}>
                        Welcome to,<br /> Prime Book Store🛍️
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 18,
                            fontWeight: 500,
                            width: "290px",
                            py: 1,
                            zIndex: 1
                        }}
                    >
                        withus, you can shop online & help save your high street at the same time.
                    </Typography>
                    <Button
                        endIcon={<EastTwoToneIcon />}
                        sx={{
                            textTransform: 'none',
                            width: '200px',
                            height: "50px",
                            border: 1,
                            fontSize: 16,
                            borderColor: 'black',
                            color: 'black',
                            borderRadius: 0,
                            transition: "all",
                            mt: 1,
                            zIndex: 1,
                            '&:hover': {
                                backgroundColor: 'primary.main',
                                color: '#fff',
                                borderRadius: 0,
                                border: 0,
                            }
                        }}
                    >
                        Explore Book
                    </Button>
                </Grid2>
                <Grid2 size={4} sx={{ p: 2, mt: 3 }}>
                    <Typography>
                        <Typography>Logo</Typography>
                    </Typography>
                    <Typography sx={{ fontSize: 15, mb: 1, }}>
                        Please add your credentials to login.
                    </Typography>
                    <Box>
                        <TextField
                            type='email'
                            label="Username or email"
                            variant="outlined"
                            fullWidth
                            value={loginData.email}
                            onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                            sx={{ mb: 1.5 }}
                            InputProps={{
                                sx: {
                                    borderRadius: 0
                                }
                            }}
                        />
                    </Box>
                    <Box>
                        <TextField
                            label="Password"
                            variant="outlined"
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={handleShowPassword} edge="end" size='small'>
                                            {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                sx: {
                                    borderRadius: 0
                                }
                            }}
                            value={loginData.password}
                            onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={rememberMe}
                                    onChange={handleRememberMe}
                                    size='small' />}

                            label="Remember me" />
                        <Button sx={{ textTransform: 'none', }}>Forgot Password ?</Button>
                    </Box>
                    <Box>

                        <Button
                            variant='contained'
                            onClick={handleSubmit}
                            endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null}
                            disabled={loading}
                            fullWidth
                            sx={{
                                textTransform: 'none',
                                borderRadius: 0,
                                height: 45,
                                mb: 1
                            }}>{loading ? 'Loading' : 'Log In'}</Button>
                        <Button
                            variant='outlined'
                            fullWidth
                            sx={{
                                textTransform: 'none',
                                borderRadius: 0,
                                height: 45

                            }}
                        >Create an Account</Button>
                    </Box>
                </Grid2>
            </Grid2>
            <Divider />
            <Grid2 container spacing={2} sx={{ px: 10, pt: 3 }}>
                <Grid2 size={3} >
                    <Typography
                        sx={{
                            fontSize: 30,
                            fontWeight: 600,
                        }}
                    >01</Typography>
                    <Box>
                        <Typography
                            sx={{ fontSize: 18, fontWeight: 600 }}
                        >Shipping 👜</Typography>
                        <Typography sx={{ fontSize: 16, }}>Worldwide shiping</Typography>
                    </Box>
                </Grid2>
                <Grid2 size={3}>
                    <Typography
                        sx={{
                            fontSize: 30,
                            fontWeight: 600,
                        }}
                    >02</Typography>
                    <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Best Price 💵</Typography>
                    <Typography sx={{ fontSize: 16 }}>Best Price with best quality</Typography>
                </Grid2>
                <Grid2 size={3}>
                    <Typography
                        sx={{
                            fontSize: 30,
                            fontWeight: 600,
                        }}
                    >03</Typography>
                    <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Authors ✍🏻</Typography>
                    <Typography sx={{ fontSize: 16 }}>Authors from around the world</Typography>
                </Grid2>
                <Grid2 size={3}>
                    <Typography
                        sx={{
                            fontSize: 30,
                            fontWeight: 600,
                        }}
                    >04</Typography>
                    <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Secure Payment 💳</Typography>
                    <Typography sx={{ fontSize: 16 }}>Safe and secure transactions</Typography>
                </Grid2>
            </Grid2>
        </Container>
    )
}

export default Login