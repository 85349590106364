import React, { useMemo, useState } from 'react';
import {
  Chip,
  Box,
  Tooltip,
  IconButton,
  Tab,
  Tabs,
  Typography,
  CircularProgress,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import CustomTable from '../../components/CustomTable';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { fetchOrders, updateOrderStatus } from '../../api/OrdersApi';
import { useNavigate } from 'react-router-dom';
import { formateDate } from '../../utilities';



const AllOrder = () => {
  const [tabvalue, setValue] = useState('1'); // Tabs state
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  let navigate = useNavigate()
  const { data: ordersData, isLoading, isError } = useQuery('orders', fetchOrders);
  const data = useMemo(() => ordersData || [], [ordersData]);

  const updateOrderMutation = useMutation(updateOrderStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries('orders');
      enqueueSnackbar('Order status updated successfully', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const getStatusColor = (status) => {
    switch (status) {
      case 'Shipped':
        return 'primary';
      case 'Pending':
        return 'warning';
      case 'Delivered':
        return 'success';
      case 'Cancelled':
        return 'error';
      default:
        return 'default';
    }
  };

  const handleChange = (event) => {
    alert(event.target.value)
    setValue(event.target.value);
  };

  const filteredOrders = useMemo(() => {
    if (tabvalue === '1') return data;
    return data.filter((order) => order.status === tabvalue);
  }, [data, tabvalue]);

  const columns = [
    { header: 'Sr.No', accessorFn: (row, index) => index + 1 },
    { header: 'Customer Name', accessorKey: 'userId.name' },
    {
      header: 'Date',
      accessorKey: 'orderDate',
      Cell: ({ cell }) => formateDate(cell.getValue())
    },
    {
      header: 'Status',
      accessorKey: 'status',
      Cell: ({ cell }) => (
        <Chip
          label={cell.getValue()}
          color={getStatusColor(cell.getValue())}
        />
      ),
    },
    { header: 'Total', accessorKey: 'total' },
    {
      header: 'Actions',
      accessorFn: (row) => row,
      Cell: ({ cell }) => (
        <Box>
          <Tooltip title="Details">
            <IconButton
              onClick={() => navigate('/admin/orderdetails', { state: cell.getValue() })}
              color="secondary">
              <EastIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];


  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">
          Failed to load orders data
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ px: 2, pt: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: ' ' }}>
        <Tabs value={tabvalue} onChange={(e) => handleChange(e)} aria-label="simple tabs example">
          <Tab key="1" label="All" value="1" />
          <Tab key="2" label="Pending" value="Pending" />
          <Tab key="3" label="Shipped" value="Shipped" />
          <Tab key="4" label="Delivered" value="Delivered" />
          <Tab key="5" label="Cancelled" value="Cancelled" />
        </Tabs>
      </Box>

      <Box>
        <CustomTable
          data={filteredOrders}
          columns={columns}
          AddComponent={<></>}
        />
      </Box>
    </Box>
  );
};

export default AllOrder;
