import axios from "axios";


export let getInstance = () => {

  // axios.defaults.withCredentials = true;
  // const BASE_URL = `http://localhost:500 0/api/`;
  const BASE_URL = "https://primebookss.com/api/api/";

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: false,
  });
  return axiosInstance
}