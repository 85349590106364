import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Container,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Button,
    CircularProgress,
    Alert,
    Pagination,
} from "@mui/material";
import { useQuery } from "react-query";
import bookimg from "../assets/Frame.png";
import { fetchBook } from "../api/BooksApi";


const Home = () => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8; // Items per page (4 rows per page)

    const { data: bookData, isLoading, isError } = useQuery("books", fetchBook);

    if (isLoading) {
        return (
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress />
            </Container>
        );
    }

    if (isError) {
        return (
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Alert severity="error">Failed to load books. Please try again later.</Alert>
            </Container>
        );
    }

    // Pagination logic
    const totalPages = Math.ceil(bookData.length / itemsPerPage);
    const currentBooks = bookData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Typography
                variant="h2"
                align="center"
                sx={{ fontWeight: "bold", color: "#1e88e5", marginBottom: 4 }}
            >
                Explore Your Next Read
            </Typography>
            <Grid container spacing={4}>
                {currentBooks.map((book) => (
                    <Grid item xs={12} sm={6} md={3} key={book._id}>
                        <Card
                            sx={{
                                maxWidth: 345,
                                cursor: "pointer",
                                transition: "transform 0.3s",
                                "&:hover": { transform: "scale(1.05)" },
                            }}
                            onClick={() =>
                                navigate(`/bookdetails`, { state: { ...book } })
                            }
                        >
                            <CardMedia
                                component="img"
                                height="300"
                                image={book.image || bookimg}
                                alt={book.title}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5">
                                    {book.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Author: {book.author}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    sx={{ marginTop: 1 }}
                                >
                                    ₹ {book.price}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    size="small"
                                    sx={{ color: "#1e88e5" }}
                                    onClick={() => navigate(`/bookdetails`)}
                                >
                                    View Details
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* Pagination Component */}
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
            />
        </Container>
    );
};

export default Home;

