// import React from 'react';
// import { Grid, Typography, Box, Link, IconButton } from '@mui/material';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import TwitterIcon from '@mui/icons-material/Twitter';

// const Footer = () => {
//     return (
//         <Box sx={{ backgroundColor: '#f8f8f8', p: 4 }}>
//             <Grid container spacing={4} justifyContent="space-between">
//                 {/* Contact Information */}
//                 <Grid item xs={12} sm={3}>
//                     <Typography variant="h6">BOOKWORM</Typography>
//                     <Typography variant="body2">1418 River Drive, Suite 35 Cottonhall, CA 9622 United States</Typography>
//                     <Typography variant="body2">sale@bookworm.com</Typography>
//                     <Typography variant="body2">+1 246-345-0695</Typography>
//                     {/* Social Media Icons */}
//                     <Box mt={2}>
//                         <IconButton href="#" size="small"><InstagramIcon /></IconButton>
//                         <IconButton href="#" size="small"><FacebookIcon /></IconButton>
//                         <IconButton href="#" size="small"><YouTubeIcon /></IconButton>
//                         <IconButton href="#" size="small"><TwitterIcon /></IconButton>
//                     </Box>
//                 </Grid>

//                 {/* Explore Section */}
//                 <Grid item xs={12} sm={2}>
//                     <Typography variant="h6">Explore</Typography>
//                     <Link href="#" underline="hover" color="inherit">About us</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Sitemap</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Bookmarks</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Sign in/Join</Link>
//                 </Grid>

//                 {/* Customer Service Section */}
//                 <Grid item xs={12} sm={2}>
//                     <Typography variant="h6">Customer Service</Typography>
//                     <Link href="#" underline="hover" color="inherit">Help Center</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Returns</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Product Recalls</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Accessibility</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Contact Us</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Store Pickup</Link>
//                 </Grid>

//                 {/* Policy Section */}
//                 <Grid item xs={12} sm={2}>
//                     <Typography variant="h6">Policy</Typography>
//                     <Link href="#" underline="hover" color="inherit">Return Policy</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Terms of Use</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Security</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Privacy</Link>
//                 </Grid>

//                 {/* Categories Section */}
//                 <Grid item xs={12} sm={2}>
//                     <Typography variant="h6">Categories</Typography>
//                     <Link href="#" underline="hover" color="inherit">Action</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Comedy</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Drama</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Horror</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Kids</Link><br />
//                     <Link href="#" underline="hover" color="inherit">Romantic Comedy</Link>
//                 </Grid>
//             </Grid>

//             {/* Payment Methods */}
//             <Box mt={4} textAlign="center">
//                 <img src="https://path-to-your-payment-icons.png" alt="Payment Methods" style={{ maxWidth: '100%' }} />
//                 <Typography variant="body2" sx={{ mt: 2 }}>© 2024 Book Worm. All rights reserved.</Typography>
//             </Box>
//         </Box>
//     );
// }

// export default Footer;



import React from 'react'
import { Box, Divider, Grid2 as Grid, Typography, TextField, Button, Container, Link, IconButton } from '@mui/material'
import { IMAGE_STORE } from '../assets'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
    return (
        <Box component="footer"
        sx={{
            position: 'relative', 
            bottom: 0,
            left: 0,
            right: 0,
            mt: 4, 
            zIndex: 10, 
        }}>
            <Divider sx={{ px: 3 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'cneter', alignItems: 'center', pt: 10 }}>
                <Typography sx={{ fontSize: "30px", fontWeight: 500 }}>Join Our Newsletter</Typography>
                <Typography sx={{ color: '#7c6e65', fontSize: "16px", fontWeight: 400, mb: 3 }}>Signup to be the first to heare about exclusive deals,special offers and upcoming collections</Typography>
                <Box display='flex' gap={2} pb={7}>
                    <TextField
                        type='text'
                        label='Enter email for weekly newsletter'
                        sx={{ width: "430px" }}
                        InputProps={{
                            sx: {
                                borderRadius: 0
                            }
                        }}
                    />
                    <Button
                        variant='contained'
                        size='large'
                        sx={{ borderRadius: 0, textTransform: 'none', width: '160px' }}
                    >
                        Subscribe
                    </Button>
                </Box>

            </Box>
            <Grid container spacing={4} justifyContent="space-between" sx={{ px: 12, pb: 10 }}>
                <Grid item xs={12} sm={3}>
                    <img src={IMAGE_STORE.logo} style={{ height: '70px' }} />
                    <Typography variant="body2" sx={{ width: '250px', py: 2 }}>Swatantrya Chowk - Pande Chowk Rd, New Joshi Colony, Jaikisan Wadi, Jalgaon, Maharashtra 425001</Typography>
                    <Typography variant="body2">primepublishinghouse.gmail.com</Typography>
                    <Typography variant="body2">+91 8888813277</Typography>
                    <Box mt={2}>
                        <IconButton href="#" size="small"><InstagramIcon /></IconButton>
                        <IconButton href="#" size="small"><FacebookIcon /></IconButton>
                        <IconButton href="#" size="small"><YouTubeIcon /></IconButton>
                        <IconButton href="#" size="small"><TwitterIcon /></IconButton>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={2}>
                    <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: '500', pb: 3 }}>Explore</Typography>
                    <Box display="flex" flexDirection="column">
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover" >About us</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover" >Sitemap</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover" >Bookmarks</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover" >Sign in/Join</Link>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: '500', pb: 3 }}>Customer Service</Typography>
                    <Box display="flex" flexDirection="column">
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover">Help Center</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover">Returns</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover">Product Recalls</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover">Accessibility</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover">Contact Us</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover">Store Pickup</Link>
                    </Box>
                </Grid>

                {/* Policy Section */}
                <Grid item xs={12} sm={2}>
                    <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: '500', pb: 3 }}>Policy</Typography>
                    <Box display="flex" flexDirection="column">
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover">Return Policy</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover">Terms of Use</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover">Security</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover">Privacy</Link>
                    </Box>
                </Grid>

                {/* Categories Section */}
                <Grid item xs={12} sm={2}>
                    <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: '500', pb: 3 }}>Categories</Typography>
                    <Box display="flex" flexDirection="column">
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover" >Action</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover" >Comedy</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover" >Drama</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover" >Horror</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover" >Kids</Link>
                        <Link sx={{ pb: 1.5, fontSize: 15 }} href="#" underline="hover" >Romantic Comedy</Link>
                    </Box>
                </Grid>
            </Grid>
            <Divider />
            <Box sx={{ p: 3 }} display="flex" justifyContent="space-between" alignItems="center" textAlign="center">
                <Typography variant='body2'>© {new Date().getFullYear()} Prime Publishing House. All rights reserved</Typography>

                <Typography variant='body2'> Design and
                    Develop by &nbsp;
                    <a
                        href="https://www.technoweit.com/"
                        target="_about"
                    >
                        Technoweit
                    </a></Typography>
            </Box>
        </Box >
    )
}

export default Footer