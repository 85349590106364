import { getInstance } from ".";



export const addOder = async (data) => {
  try {
    const response = await getInstance().post("addorder", data);
    return response.data;
  } catch (error) {
    console.error("login error", error.response || error.message);
    throw new Error(error.response?.data?.message || error.message);
  }
}

export const fetchOrders = async () => {
  try {
    const response = await getInstance().get("orders");
    return response.data;
  } catch (error) {
    console.log("fetch all order error", error.message);
    throw new Error(error.response?.data?.message || error.message);
  }
};

export let fetchOrdersByCustId = async ({ queryKey }) => {

  let [_, reqData] = queryKey
  try {
    let response = await getInstance().post('orderbycustid', reqData)
    return response.data
  } catch (error) {
    console.log("fetch all order error", error.message);
    throw new Error(error.response?.data?.message || error.message);
  }
}

export let updateOrderStatus = async (reqdata) => {

  try {
    let response = await getInstance().put('updateorderstatus', reqdata)
    return response.data
  } catch (error) {
    console.log("fetch all order error", error.message);
    throw new Error(error.response?.data?.message || error.message);
  }
}

