import React, { useEffect, useState } from 'react';
import {
  Container, Typography, Grid, Box, Button, TextField, MenuItem, Divider, IconButton, Tabs, Tab, CircularProgress, Alert
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '../redux/CartSlice';
import { fetchBookById } from '../api';
import bookimg from '../assets/Frame.png';

const BookDetails = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const location = useLocation();
  const bookData = location.state;

  const [subTotal, setsubTotal] = useState(bookData.price)
  const [quantity, setQuantity] = useState(1)
  const [selectedFormat, setSelectedFormat] = useState('Hardcover');
  const [tabValue, setTabValue] = useState(0);

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value)
    setsubTotal(bookData.price * Number(quantity))
  };
  const handleFormatChange = (e) => setSelectedFormat(e.target.value);
  const handleTabChange = (e, newValue) => setTabValue(newValue);

  const handleAddToCart = () => {
    const item = {
      ...bookData,
      quantity: Number(quantity),
      format: selectedFormat,
      subTotal: subTotal
    };

    dispatch(addItem(item));
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {console.log('Cart Items:', cartItems)}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Box component="img" src={bookData.image || bookimg} alt="Book Cover" sx={{ width: '100%', borderRadius: 1 }} />
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="h4" gutterBottom>{bookData.title}</Typography>
          <Typography variant="subtitle1" color="text.secondary">By {bookData.author}</Typography>
          <Typography variant="h5" sx={{ mt: 2 }}> ₹ {bookData.price}</Typography>

          {/* <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Book Format:</Typography>
          <TextField
            select
            label="Choose an option"
            value={selectedFormat}
            onChange={handleFormatChange}
            fullWidth
            sx={{ mb: 2 }}
          >
            {['Hardcover', 'Paperback', 'Kindle'].map((format) => (
              <MenuItem key={format} value={format}>
                {format} - ₹ 9.59
              </MenuItem>
            ))}
          </TextField> */}

          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            {bookData.description}
          </Typography>

          <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ mr: 2 }}>Quantity</Typography>
            <TextField
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
              size="small"
              sx={{ width: 60 }}
              inputProps={{ min: 1 }}
            />
          </Box>

          {/* <Box display="flex" sx={{ mt: 2 }}>
            <Button startIcon={<FavoriteBorderIcon />} sx={{ color: 'black' }}>Add to Wishlist</Button>
            <IconButton sx={{ ml: 2 }}>
              <ShareIcon />
            </IconButton>
          </Box> */}
          <Button
            variant="contained"
            onClick={handleAddToCart}
            sx={{ backgroundColor: 'black', color: 'white' }}
            fullWidth
          >
            Add to Cart - ₹ {bookData.price * quantity}
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth" sx={{ mb: 2 }}>
        <Tab label="Description" />
        <Tab label="Product Details" />
        <Tab label="Videos" />
        <Tab label="Reviews (0)" />
      </Tabs>

      <Box sx={{ p: 2, border: '1px solid black', borderRadius: 1 }}>
        {tabValue === 0 && (
          <Typography variant="body1" color="text.secondary">
            {bookData.description}
          </Typography>
        )}
        {tabValue === 1 && (
          <Box>
            <Typography variant="body1" color="text.secondary">
              Format: {bookData.format} | {bookData.pages} pages<br />
              Dimensions: {bookData.dimensions} | {bookData.weight}g<br />
              Publication date: bookData.publicationDate}<br />
              Publisher: {bookData.publisher}<br />
              Language: {bookData.language}
            </Typography>
          </Box>
        )}
        {tabValue === 2 && (
          <Typography variant="body1" color="text.secondary">
            No videos available.
          </Typography>
        )}
        {tabValue === 3 && (
          <Typography variant="body1" color="text.secondary">
            No reviews available.
          </Typography>
        )}
      </Box>

      <Divider sx={{ my: 3 }} />

      <Button
        variant="contained"
        onClick={handleAddToCart}
        sx={{ backgroundColor: 'black', color: 'white' }}
        fullWidth
      >
        Add to Cart - ${subTotal}
      </Button>
    </Container>
  );
};

export default BookDetails;
