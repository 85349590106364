import { getInstance } from ".";

export const fetchBook = async () => {
    try {
        const response = await getInstance().get("books");
        return response.data;
    } catch (error) {
        console.log("fetch all book error", error.message);
        throw new Error(error.response?.data?.message || error.message);
    }
};

export const createBook = async (data) => {
    try {
        const response = await getInstance().post("/addbook", data);
        return response.data;
    } catch (error) {
        console.log("add book erroor", error.response || error.message);
        throw new Error(error.response?.data?.message || error.message);
    }
};

export const deleteBook = async (id) => {
    try {
        const response = await getInstance().delete(`deletebook/${id}`);
        return response.data;
    } catch (error) {
        console.error("delete book error:", error.response || error.message);
        throw new Error(error.response?.data?.message || error.message);
    }
};
export const fetchBookById = async (id) => {
    try {
        const response = await getInstance().get(`books/${id}`);
        return response.data;
    } catch (error) {
        console.error("fetch book by ID error:", error.response || error.message);
        throw new Error(error.response?.data?.message || error.message);
    }
};