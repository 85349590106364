import React from 'react';
import { Container, Typography, TextField, Button, IconButton, Box, Paper, Divider } from '@mui/material';
import { Instagram, Facebook, YouTube, Twitter, Pinterest } from '@mui/icons-material';

const Contact = () => {
    return (
        <>
            <Box sx={{ backgroundColor: '#f3f4f6', py: 8, position: 'relative' }}>
                <Box sx={{ textAlign: 'center', mb: 6 }}>
                    <Typography variant="h4" fontWeight={700} color="primary">Contact Us</Typography>
                    <Typography variant="body1" color="textSecondary" fontStyle="italic">
                        We’d love to hear from you! Get in touch with us.
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative', mb: 6 }}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.71779771794!2d75.56163297343302!3d21.003946388633832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90f46ac9b40b5%3A0xa451df0d07d8dc67!2zUFJJTUUgQk9PS1MgLSBQVUJMSVNISU5HIEhPVVNFICjgpKrgpY3gpLDgpL7gpIjgpK4g4KSs4KWB4KSV4KWN4KS4IOCkquCkrOCljeCksuCkv-CktuCkv-CkguCklyDgpLngpL7gpIrgpLgp!5e0!3m2!1sen!2sin!4v1727337284845!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        style={{ border: 0, borderRadius: '8px' }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>

                    <Paper
                        elevation={4}
                        sx={{
                            position: 'absolute',
                            top: '85%',
                            width: { xs: '90%', sm: '60%' },
                            left: '50%',
                            transform: 'translateX(-50%)',
                            p: 5,
                            borderRadius: 3,
                            background: 'linear-gradient(135deg, #ffffff, #f0f2f5)',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Typography variant="h5" fontWeight={600} color="textPrimary" gutterBottom>
                            Contact Information
                        </Typography>
                        <Typography variant="body1" color="textSecondary" fontStyle="italic" sx={{ mb: 3 }}>
                            We’re here to answer any questions about our services or partnerships.
                        </Typography>

                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle1" fontWeight={600}>Jalgaon Office</Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ width: '80%', mb: 2 }}>
                                Swatantrya Chowk - Pande Chowk Rd, New Joshi Colony, Jaikisan Wadi, Jalgaon, Maharashtra 425001
                            </Typography>
                        </Box>

                        {/* Social Media Icons */}
                        <Box sx={{ display: 'flex', gap: 1, mb: 4 }}>
                            <IconButton href="#" color="primary">
                                <Instagram />
                            </IconButton>
                            <IconButton href="#" color="primary">
                                <Facebook />
                            </IconButton>
                            <IconButton href="#" color="primary">
                                <YouTube />
                            </IconButton>
                            <IconButton href="#" color="primary">
                                <Twitter />
                            </IconButton>
                            <IconButton href="#" color="primary">
                                <Pinterest />
                            </IconButton>
                        </Box>

                        <Typography variant="h5" fontWeight={600} color="textPrimary" gutterBottom>
                            Get In Touch
                        </Typography>
                        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box display="flex" gap={2}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    variant="outlined"
                                    size="small"
                                    sx={{ borderRadius: '4px' }}
                                />
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    size="small"
                                    sx={{ borderRadius: '4px' }}
                                />
                            </Box>
                            <TextField
                                fullWidth
                                label="Subject"
                                variant="outlined"
                                size="small"
                                sx={{ borderRadius: '4px' }}
                            />
                            <TextField
                                fullWidth
                                label="Message"
                                variant="outlined"
                                multiline
                                rows={4}
                                sx={{ borderRadius: '4px' }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: '50%',
                                    alignSelf: 'center',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    borderRadius: '4px',
                                    textTransform: 'none',
                                    ':hover': {
                                        backgroundColor: '#003366'
                                    }
                                }}
                            >
                                Submit Message
                            </Button>
                        </Box>
                    </Paper>
                </Box>
            </Box>

        </>
    );
};

export default Contact;
