// import React, { useState } from 'react';
// import { Box, Typography, IconButton, TextField, InputAdornment, Drawer, useMediaQuery, ListItem, ListItemText, List, Divider, AppBar, Toolbar, Button, MenuList, Avatar, Tooltip, ListItemButton, ListItemIcon, Popover, Fade, Menu, Paper, MenuItem } from '@mui/material';
// import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone';
// import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
// import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
// import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// import SearchIcon from '@mui/icons-material/Search';
// import SegmentTwoToneIcon from '@mui/icons-material/SegmentTwoTone';
// import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
// import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
// import { FaInstagram, FaXTwitter, FaFacebook, FaGoogle } from "react-icons/fa6";
// import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
// import MenuIcon from '@mui/icons-material/Menu';
// import PersonIcon from '@mui/icons-material/Person';
// import Sidebar from './Sidebar';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux'
// import { VscAccount } from "react-icons/vsc";
// import { LiaShippingFastSolid } from "react-icons/lia";
// import { PiShoppingCart } from "react-icons/pi";
// import Cart from '../pages/Cart';
// import { MdOutlineAccountCircle } from "react-icons/md";
// import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
// import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
// import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
// import { useMutation, useQueryClient, useQuery } from 'react-query'
// import { userLogout } from '../api';
// import { useSnackbar } from "notistack"
// import { logout } from '../redux/UserSlice'
// import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
// import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';

// const MENU_ITEM = [
//   { name: 'Bestsellers', path: "/" },
//   // { name: 'New Arrival', path: "/bestseller" },
//   // { name: 'Academics', path: "/bestseller" },
//   // { name: 'Languages', path: "/bestseller" },
//   // { name: 'Store', path: "/bestseller" },
//   // { name: 'Author', path: "/author" },
//   { name: 'About', path: "/about" },
//   { name: 'Contact Us', path: "/contact" },
// ]

// const Header = () => {

//   const navigate = useNavigate();
//   const queryClient = useQueryClient();
//   const { enqueueSnackbar } = useSnackbar();
//   const dispatch = useDispatch();
//   const { userData } = useSelector((state) => state.user);
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [profileBox, setProfileBox] = useState(null);
//   const openProfile = Boolean(profileBox)
//   const isMobile = useMediaQuery('(max-width:600px)');



//   const [cartDrawer, setCartDrawer] = useState(false);
//   const toggleCartDrawer = (newOpen) => () => setCartDrawer(newOpen)



//   const handleClick = (event) => setProfileBox(event.currentTarget);
//   const handleClose = () => setProfileBox(null);


//   const toggleDrawer = (open) => () => setDrawerOpen(!open);

//   const menuList = () => (
//     <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
//       <List>
//         {MENU_ITEM.map((text, index) => (
//           <ListItem button key={index}>
//             <ListItemText primary={text} />
//             <KeyboardArrowRightTwoToneIcon />
//           </ListItem>
//         ))}
//       </List>
//     </Box>
//   )

//   const [open, setOpen] = useState(false);
//   const toggleADrawer = (newOpen) => () => {
//     setOpen(newOpen);
//   };


//   const logoutMutation = useMutation(userLogout, {
//     onSuccess: () => {
//       localStorage.removeItem('token');
//       dispatch(logout());
//       enqueueSnackbar('Logout successfully', { variant: "success" });
//       navigate('/')
//     },
//     onError: (error) => {
//       enqueueSnackbar('Error logout', error, { variant: 'error' })
//     }

//   })

//   const handleLogout = () => {
//     logoutMutation.mutate()
//   }


//   const handleLogin = () => {
//     navigate('/signup');
//   };

//   const goToCart = () => {
//     navigate('/cart');
//   };

//   const goToOrders = () => {
//     navigate('/orders')
//   }

//   return (
//     <>
//       {'has' === 'Admin' ? (
//         <Box sx={{
//           height: '55px',
//           borderBottom: 1,
//           borderColor: 'divider',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'space-between',
//           px: 1.5,
//           zIndex: 1300,
//           bgcolor: '#ffffff',
//           position: 'relative'
//         }}>
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <IconButton onClick={() => setOpen(!open)}>
//               {open ? <MenuOpenOutlinedIcon /> : <MenuOutlinedIcon />}
//             </IconButton>
//             <Typography sx={{ fontSize: '17px', fontWeight: 500, cursor: 'pointer' }}>Prime Publishing House</Typography>
//           </Box>

//           <Box>
//             <Tooltip title="notification">
//               <IconButton>
//                 <NotificationsNoneOutlinedIcon />
//               </IconButton>
//             </Tooltip>
//             <Tooltip title="mail">
//               <IconButton  >
//                 <EmailOutlinedIcon />
//               </IconButton>
//             </Tooltip>
//             <Tooltip title="profile">
//               <IconButton onClick={handleClick} >
//                 <MdOutlineAccountCircle />
//               </IconButton>
//             </Tooltip>
//           </Box>

//           <Menu
//             anchorEl={profileBox}
//             open={openProfile}
//             onClose={handleClose}
//             MenuListProps={{
//               disablePadding: true
//             }}
//             sx={{ borderWidth: 1, borderColor: '#ccc' }}
//           >
//             <Paper sx={{ width: 270 }}>
//               <MenuList dense>
//                 <MenuItem>
//                   <ListItemIcon>
//                     <Avatar sx={{ width: 39, height: 39, fontSize: "16px", mr: 1, bgcolor: "pink" }}>HM</Avatar>
//                   </ListItemIcon>
//                   <Box sx={{ flexGrow: 1 }}>
//                     <Typography>Harshal Mahajan</Typography>
//                   </Box>
//                   <Box sx={{
//                     width: 8,
//                     height: 8,
//                     borderRadius: '50%',
//                     bgcolor: '#1FFF0F',
//                     ml: 1,
//                     '@keyframes heartbeat': {
//                       '0%': {
//                         transform: 'scale(1)',
//                       },
//                       '25%': {
//                         transform: 'scale(1.2)',
//                       },
//                       '50%': {
//                         transform: 'scale(1)',
//                       },
//                       '75%': {
//                         transform: 'scale(1.2)',
//                       },
//                       '100%': {
//                         transform: 'scale(1)',
//                       },
//                     },
//                     animation: 'heartbeat 1.5s infinite',
//                   }} />
//                 </MenuItem>

//                 <Divider />

//                 <MenuItem>
//                   <ListItemIcon>
//                     <MarkEmailReadRoundedIcon />
//                   </ListItemIcon>
//                   Mail
//                 </MenuItem>
//                 <MenuItem>
//                   <ListItemIcon>
//                     <SettingsRoundedIcon />
//                   </ListItemIcon>
//                   Setting
//                 </MenuItem>
//                 <Divider />
//                 <MenuItem onClick={handleLogout}>
//                   <ListItemIcon>
//                     <LogoutRoundedIcon />
//                   </ListItemIcon>
//                   Logout
//                 </MenuItem>
//               </MenuList>
//             </Paper>
//           </Menu>
//           <Drawer
//             open={open}
//             onClose={toggleADrawer(false)}
//             ModalProps={{
//               keepMounted: true,
//             }}
//             PaperProps={{
//               sx: {
//                 zIndex: 1200,
//                 px: 0
//               }
//             }}

//           >
//             <Box sx={{ width: 220, mt: "40px" }} role="presentation" onClick={() => toggleADrawer(false)}>
//               <Sidebar />
//             </Box>
//           </Drawer>
//         </Box >
//       ) : (
//         <>
//           <Box sx={{
//             height: '45px',
//             display: isMobile ? "none" : "flex",
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             px: 2,
//             borderBottom: 1,
//             borderColor: 'divider'
//           }}>
//             <Box sx={{ display: 'flex' }}>
//               <Box sx={{ display: 'flex', mr: 3 }}>
//                 <HelpOutlineTwoToneIcon fontSize='small' color='inherit' sx={{ mr: 1 }} />
//                 <Typography>Can we Help you ?</Typography>
//               </Box>
//               <Box sx={{ display: 'flex' }}>
//                 <PhoneTwoToneIcon fontSize='small' sx={{ mr: 1 }} />
//                 <Typography>+91 8888813277</Typography>
//               </Box>
//             </Box>
//             <Box display="flex" alignItems="center">
//               <Button
//                 color='error'
//                 startIcon={<FavoriteBorderOutlinedIcon />}
//                 sx={{ textTransform: 'none', fontSize: '16px', fontWeight: "450" }}
//               >
//                 Wishlist
//               </Button>
//               <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 1 }} />
//               <Button
//                 color='black'
//                 startIcon={< PiShoppingCart />}
//                 onClick={() => goToCart()}
//                 sx={{
//                   textTransform: 'none', fontSize: '16px', fontWeight: "400", '&:hover': {
//                     color: 'primary.main',
//                     cursor: 'pointer',
//                   }
//                 }}
//               >
//                 Cart
//               </Button>
//               <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 1 }} />
//               <Button
//                 color='black'
//                 startIcon={< LiaShippingFastSolid />}
//                 sx={{
//                   textTransform: 'none', fontSize: '16px', fontWeight: "400", '&:hover': {
//                     color: 'primary.main',
//                     cursor: 'pointer',
//                   }
//                 }}
//                 onClick={() => goToOrders()}
//               >
//                 Track your order
//               </Button>
//               <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 1 }} />
//               {userData && userData.name ? (
//                 <>
//                   <Button
//                     color='black'
//                     startIcon={<VscAccount />}
//                     onMouseEnter={handleClick}
//                     disableRipple
//                     sx={{
//                       textTransform: 'none', fontSize: '16px', fontWeight: "400",
//                       '&:hover': {
//                         color: 'primary.main',
//                         cursor: 'pointer',
//                       }

//                     }}
//                   >
//                     {userData.name}
//                   </Button>
//                   <Menu
//                     anchorEl={profileBox}
//                     open={openProfile}
//                     onClose={handleClose}
//                     MenuListProps={{
//                       disablePadding: true
//                     }}
//                   >
//                     <Paper sx={{ width: 270 }}>
//                       <MenuList dense>
//                         <Typography>Hello</Typography>
//                       </MenuList>
//                     </Paper>
//                   </Menu>
//                 </>

//               ) : (
//                 <Button
//                   color='black'
//                   onClick={handleLogin}
//                   startIcon={<PersonIcon />}
//                   sx={{
//                     textTransform: 'none', fontSize: '16px', fontWeight: "400", '&:hover': {
//                       color: 'primary.main',
//                       cursor: 'pointer',
//                     }
//                   }}
//                 >
//                   Login / SignUp
//                 </Button>
//               )}
//             </Box>
//           </Box>
//           <Box sx={{
//             height: isMobile ? "65px" : '70px',
//             borderBottom: 1,
//             borderColor: 'divider',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//             px: isMobile ? 1 : 3
//           }}>
//             <Box sx={{ display: 'flex', width: isMobile ? "100%" : "150px", flexDirection: isMobile ? 'row-reverse' : 'row', alignItems: 'center', justifyContent: 'space-between' }}>
//               <Box sx={{ display: isMobile ? 'block' : 'none' }}>
//                 <IconButton>
//                   <NotificationsNoneOutlinedIcon />
//                 </IconButton>
//                 <IconButton>
//                   <FavoriteBorderOutlinedIcon />
//                 </IconButton>
//                 <IconButton onClick={() => navigate('/cart')}>
//                   <ShoppingCartOutlinedIcon />
//                 </IconButton>
//                 <IconButton onClick={() => toggleDrawer(true)} color='primary' >
//                   <SegmentTwoToneIcon />
//                 </IconButton>
//               </Box>
//               <Box sx={{ width: '160px', textAlign: isMobile ? 'left' : 'center' }}>
//                 <Typography>PRIME</Typography>
//               </Box>
//             </Box>

//             {!isMobile && (
//               <Box sx={{ display: 'flex', gap: 3 }}>
//                 {MENU_ITEM.map((item, index) => (
//                   <Typography
//                     key={index}
//                     sx={{
//                       position: 'relative',
//                       fontWeight: 450,
//                       '&:hover': {
//                         color: 'primary.main',
//                         cursor: 'pointer',
//                         '&::after': {
//                           content: '""',
//                           position: 'absolute',
//                           bottom: 0,
//                           left: 0,
//                           width: '100%',
//                           height: '2px',
//                           backgroundColor: 'primary.main',
//                           transition: 'width 0.3s ease',
//                         }
//                       },
//                       '&::after': {
//                         content: '""',
//                         position: 'absolute',
//                         bottom: 0,
//                         left: 0,
//                         width: 0,
//                         height: '2px',
//                         backgroundColor: 'primary.main',
//                         transition: 'width 0.3s ease'
//                       }
//                     }}
//                     onClick={() => navigate(item.path)}
//                   >
//                     {item.name}
//                   </Typography>
//                 ))}
//               </Box>
//             )}

//             {!isMobile && (
//               <TextField
//                 variant='outlined'
//                 size='small'
//                 placeholder='search for books by keywords...'
//                 sx={{
//                   width: '300px',
//                   borderRadius: '5px',
//                   bgcolor: '#f6f5f3',
//                   '& .MuiOutlinedInput-root': {
//                     border: 'none',
//                     borderRadius: '5px',
//                     '& fieldset': {
//                       border: 'none',
//                     },
//                   },
//                   '& .MuiInputAdornment-root': {
//                     marginRight: '8px',
//                   }
//                 }}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <SearchIcon />
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//             )}
//           </Box >

//         </>
//       )}

//     </>
//   )
// }

// export default Header;


// // import React from 'react'

// // function Header() {
// //   return (
// //     <div>
// //       gg
// //     </div>
// //   )
// // }

// // export default Header







import React, { useState } from 'react';
import { Box, Typography, IconButton, TextField, InputAdornment, Drawer, useMediaQuery, ListItem, ListItemText, List, Divider, AppBar, Toolbar, Button, MenuList, Avatar, Tooltip, ListItemButton, ListItemIcon, Popover, Fade, Menu, Paper, MenuItem } from '@mui/material';
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone';
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SegmentTwoToneIcon from '@mui/icons-material/SegmentTwoTone';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { FaInstagram, FaXTwitter, FaFacebook, FaGoogle } from "react-icons/fa6";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { VscAccount } from "react-icons/vsc";
import { LiaShippingFastSolid } from "react-icons/lia";
import { PiShoppingCart } from "react-icons/pi";
import Cart from '../pages/Cart';
import { MdOutlineAccountCircle } from "react-icons/md";
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { useSnackbar } from "notistack"
import { logout } from '../redux/UserSlice'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import { userLogout } from '../api/UserApi.jsx'

const MENU_ITEM = [{ title: 'Bestsellers', path: '/' },
{ title: 'New Arrival', path: '/newarrival' },
{ title: "Academics", path: '/academics' },
{ title: "Languages", path: '/languages' },
{ title: "Store", path: '/store' },
{ title: "Author", path: '/author' },
{ title: "About", path: '/about' },
{ title: "Contact Us", path: '/contact' }]

const Header = () => {

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [profileBox, setProfileBox] = useState(null);
  const openProfile = Boolean(profileBox)
  const isMobile = useMediaQuery('(max-width:600px)');



  const [cartDrawer, setCartDrawer] = useState(false);
  const toggleCartDrawer = (newOpen) => () => setCartDrawer(newOpen)



  const handleClick = (event) => setProfileBox(event.currentTarget);
  const handleClose = () => setProfileBox(null);


  const toggleDrawer = (open) => () => setDrawerOpen(open);

  const menuList = () => (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        {MENU_ITEM.map((item, index) => (
          <ListItem onClick={() => navigate(item.path)} button key={index}>
            <ListItemText primary={item.title} />
            <KeyboardArrowRightTwoToneIcon />
          </ListItem>
        ))}
      </List>
    </Box>
  )

  const [open, setOpen] = useState(false);
  const toggleADrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  const logoutMutation = useMutation(userLogout, {
    onSuccess: () => {
      localStorage.removeItem('token');
      dispatch(logout());
      enqueueSnackbar('Logout successfully', { variant: "success" });
      navigate('/')
    },
    onError: (error) => {
      enqueueSnackbar('Error logout', error, { variant: 'error' })
    }

  })

  const handleLogout = () => {
    logoutMutation.mutate()
  }


  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <>
      {userData.role === 'Admin' ? (
        <Box sx={{
          height: '55px',
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 1.5,
          zIndex: 1300,
          bgcolor: '#ffffff',
          position: 'relative'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={toggleADrawer(true)}>
              {open ? <MenuOpenOutlinedIcon /> : <MenuOutlinedIcon />}
            </IconButton>
            <Typography sx={{ fontSize: '17px', fontWeight: 500, cursor: 'pointer' }}>Prime Publishing House</Typography>
          </Box>

          <Box>
            <Tooltip title="notification">
              <IconButton>
                <NotificationsNoneOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="mail">
              <IconButton  >
                <EmailOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="profile">
              <IconButton onClick={handleClick} >
                <MdOutlineAccountCircle />
              </IconButton>
            </Tooltip>
          </Box>

          <Menu
            anchorEl={profileBox}
            open={openProfile}
            onClose={handleClose}
            MenuListProps={{
              disablePadding: true
            }}
            sx={{ borderWidth: 1, borderColor: '#ccc' }}
          >
            <Paper sx={{ width: 270 }}>
              <MenuList dense>
                <MenuItem>
                  <ListItemIcon>
                    <Avatar sx={{ width: 39, height: 39, fontSize: "16px", mr: 1, bgcolor: "pink" }}>HM</Avatar>
                  </ListItemIcon>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography>Harshal Mahajan</Typography>
                  </Box>
                  <Box sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    bgcolor: '#1FFF0F',
                    ml: 1,
                    '@keyframes heartbeat': {
                      '0%': {
                        transform: 'scale(1)',
                      },
                      '25%': {
                        transform: 'scale(1.2)',
                      },
                      '50%': {
                        transform: 'scale(1)',
                      },
                      '75%': {
                        transform: 'scale(1.2)',
                      },
                      '100%': {
                        transform: 'scale(1)',
                      },
                    },
                    animation: 'heartbeat 1.5s infinite',
                  }} />
                </MenuItem>

                <Divider />

                <MenuItem>
                  <ListItemIcon>
                    <MarkEmailReadRoundedIcon />
                  </ListItemIcon>
                  Mail
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <SettingsRoundedIcon />
                  </ListItemIcon>
                  Setting
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutRoundedIcon />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </MenuList>
            </Paper>
          </Menu>
          <Drawer
            open={open}
            onClose={toggleADrawer(false)}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: {
                zIndex: 1200,
                px: 0
              }
            }}

          >
            <Box sx={{ width: 220, mt: "40px" }} role="presentation" onClick={toggleADrawer(false)}>
              <Sidebar />
            </Box>
          </Drawer>
        </Box >
      ) : (
        <>
          <Box sx={{
            height: '45px',
            display: isMobile ? "none" : "flex",
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 2,
            borderBottom: 1,
            borderColor: 'divider'
          }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex', mr: 3 }}>
                <HelpOutlineTwoToneIcon fontSize='small' color='inherit' sx={{ mr: 1 }} />
                <Typography>Can we Help you ?</Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <PhoneTwoToneIcon fontSize='small' sx={{ mr: 1 }} />
                <Typography>+91 8888813277</Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Button
                color='error'
                startIcon={<FavoriteBorderOutlinedIcon />}
                sx={{ textTransform: 'none', fontSize: '16px', fontWeight: "450" }}
              >
                Wishlist
              </Button>
              <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 1 }} />
              <Button
                color='black'
                startIcon={< PiShoppingCart />}
                onClick={() => { navigate('/cart') }}
                sx={{
                  textTransform: 'none', fontSize: '16px', fontWeight: "400", '&:hover': {
                    color: 'primary.main',
                    cursor: 'pointer',
                  }
                }}
              >
                Cart
              </Button>
              <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 1 }} />
              <Button
                color='black'
                onClick={() => navigate('/orders')}
                startIcon={< LiaShippingFastSolid />}
                sx={{
                  textTransform: 'none', fontSize: '16px', fontWeight: "400", '&:hover': {
                    color: 'primary.main',
                    cursor: 'pointer',
                  }
                }}
              >
                Track your order
              </Button>
              <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 1 }} />
              {userData && userData.name ? (
                <>
                  <Button
                    color='black'
                    startIcon={<VscAccount />}
                    onMouseEnter={handleClick}
                    disableRipple
                    sx={{
                      textTransform: 'none', fontSize: '16px', fontWeight: "400",
                      '&:hover': {
                        color: 'primary.main',
                        cursor: 'pointer',
                      }

                    }}
                  >
                    {userData.name}
                  </Button>
                  <Menu
                    anchorEl={profileBox}
                    open={openProfile}
                    onClose={handleClose}
                    MenuListProps={{
                      disablePadding: true
                    }}
                  >
                    <Paper sx={{ width: 270 }}>
                      <MenuList dense>
                        <Typography>Hello</Typography>
                      </MenuList>
                    </Paper>
                  </Menu>
                </>

              ) : (
                <Button
                  color='black'
                  onClick={handleLogin}
                  startIcon={<PersonIcon />}
                  sx={{
                    textTransform: 'none', fontSize: '16px', fontWeight: "400", '&:hover': {
                      color: 'primary.main',
                      cursor: 'pointer',
                    }
                  }}
                >
                  Login / SignUp
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={{
            height: isMobile ? "65px" : '70px',
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: isMobile ? 1 : 3
          }}>
            <Box sx={{ display: 'flex', width: isMobile ? "100%" : "150px", flexDirection: isMobile ? 'row-reverse' : 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: isMobile ? 'block' : 'none' }}>
                <IconButton>
                  <ShoppingBagIcon />
                </IconButton>
                <IconButton>
                  <FavoriteBorderOutlinedIcon />
                </IconButton>
                <IconButton onClick={() => navigate('/cart')}>
                  <ShoppingCartOutlinedIcon />
                </IconButton>
                <IconButton onClick={toggleDrawer(true)} color='primary' >
                  <SegmentTwoToneIcon />
                </IconButton>
              </Box>
              <Box sx={{ width: '160px', textAlign: isMobile ? 'left' : 'center' }}
                onClick={() => navigate('/')}>
                <Typography>PRIME</Typography>
              </Box>
            </Box>

            {!isMobile && (
              <Box sx={{ display: 'flex', gap: 3 }}>
                {MENU_ITEM.map((item, index) => (
                  <Typography
                    onClick={() => navigate(item.path)}
                    key={index}
                    sx={{
                      position: 'relative',
                      fontWeight: 450,
                      '&:hover': {
                        color: 'primary.main',
                        cursor: 'pointer',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: '100%',
                          height: '2px',
                          backgroundColor: 'primary.main',
                          transition: 'width 0.3s ease',
                          width: '100%'
                        }
                      },
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: 0,
                        height: '2px',
                        backgroundColor: 'primary.main',
                        transition: 'width 0.3s ease'
                      }
                    }}
                  >
                    {item.title}
                  </Typography>
                ))}
              </Box>
            )}

            {!isMobile && (
              <TextField
                variant='outlined'
                size='small'
                placeholder='search for books by keywords...'
                sx={{
                  width: '300px',
                  borderRadius: '5px',
                  bgcolor: '#f6f5f3',
                  '& .MuiOutlinedInput-root': {
                    border: 'none',
                    borderRadius: '5px',
                    '& fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputAdornment-root': {
                    marginRight: '8px',
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box >
          <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            {menuList()}
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography sx={{ fontSize: "18px", fontWeight: 500, mb: 0.2 }}>Other</Typography>
              <Typography sx={{ fontSize: "16px", pb: 0.4, pl: 1 }}>Help</Typography>
              <Typography sx={{ fontSize: "16px", pb: 0.4, pl: 1 }}>FAQ</Typography>
              <Typography sx={{ fontSize: "16px", pb: 0.4, pl: 1 }}>Term & Conditions</Typography>
              <Typography sx={{ fontSize: "16px", pb: 0.4, pl: 1 }}>Sign In</Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                <EmailOutlinedIcon sx={{ mr: 1 }} fontSize='small' />
                <Typography>harshalmm143@gmail.com</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LocalPhoneOutlinedIcon sx={{ mr: 1 }} fontSize='small' />
                <Typography>1234567890</Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, mt: 3 }}>
              <FaGoogle size={20} />
              <FaXTwitter size={20} />
              <FaInstagram size={20} />
              <FaFacebook size={20} />
            </Box>
          </Drawer>

          <Drawer
            anchor='right'
            open={cartDrawer}
            onClose={toggleCartDrawer(false)}

          >
            <Box sx={{ width: "600px" }}>
              <Cart />
            </Box>
          </Drawer>
        </>
      )}

    </>
  )
}

export default Header;