import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#f50057',
        },
        error: {
            main: '#d32f2f',
        },
        background: {
            default: '#fffff',
        },
        text: {
            primary: '#333333',
            secondary: '#888888',
        },
        divider: '#aaaaaa',
    },
    typography: {
        fontFamily: 'Inter , Arial, sans-serif',
    },
    components: {

        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 0,

                    },
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 1,
            },
            styleOverrides: {
                root: {
                    padding: 16,
                },
            },
        },
        MuiCard: {
            defaultProps: {
                elevation: 1,
            },
            styleOverrides: {
                root: {
                    padding: 16,
                },
            },
        },

        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    color: '#333333',
                    borderRadius: '5px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    backdropFilter: 'blur(5px)',
                    fontSize: '0.875rem',
                },
                arrow: {
                    color: 'rgba(255, 255, 255, 0.8)',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#ffffff',
                    color: '#333333',
                    borderRight: '1px solid #ccc',
                },
                root: {
                    '& .MuiDrawer-paper': {
                        backgroundColor: '#ffffff',
                        color: '#333333',
                    },
                    '& .MuiListItem-root:hover': {
                        backgroundColor: '#eeeeee',
                    },
                    '& .MuiListItem-root.Mui-selected': {
                        backgroundColor: '#1976d2',
                        color: '#ffffff',
                    },
                },
            },
        },

    },
})