import React, { useState } from 'react'
import { Box, Breadcrumbs, Typography, Link, Divider, Grid2, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const authors = [
    { id: 1, name: "Alice Adams" },
    { id: 2, name: "Brian Brown" },
    { id: 3, name: "Charlie Cox" },
    { id: 4, name: "David Davis" },
    { id: 5, name: "Eve Evans" },
    { id: 6, name: "Fiona Fisher" },
    { id: 7, name: "George Green" },
    { id: 8, name: "Harry Hughes" },
    { id: 9, name: "Ivy Irving" },
    { id: 10, name: "Jack Jones" },
    { id: 11, name: "Karen Kelly" },
    { id: 12, name: "Leo Lewis" },
    // More authors...
];

const Authors = () => {

    const alphabet = ['ALL', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const [selectedLetter, setSelectedLetter] = useState('ALL');
    const [filteredAuthors, setFilteredAuthors] = useState(authors);

    const handleClick = (letter) => {
        // You can add more logic here when a letter is clicked
    };

    const handleAlphabetClick = (letter) => {
        if (letter === 'ALL') {
            setFilteredAuthors(authors);
        } else {
            const newAuthors = authors.filter((author) => author.name.startsWith(letter));
            setFilteredAuthors(newAuthors);
        }
        setSelectedLetter(letter);
    };

    return (
        <Box>
            <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography>Authors</Typography>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Typography underline="hover" key="1" color="inherit" sx={{ color: 'text.primary' }}>
                        Home
                    </Typography>
                    <Typography key="2" sx={{ color: 'text.primary' }}>
                        Authors
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 4,
                    mt: 5,

                }}
            >
                {alphabet.map((letter) => (
                    <Typography
                        key={letter}
                        onClick={() => handleAlphabetClick(letter)}
                        sx={{
                            cursor: 'pointer',
                            fontSize: 14,
                            color: selectedLetter === letter ? 'black' : 'gray',
                            fontWeight: selectedLetter === letter ? '450' : '400',
                            position: 'relative',
                            '&:before': {
                                content: '""',
                                position: 'absolute',
                                bottom: '-4px',
                                left: 0,
                                width: selectedLetter === letter ? '100%' : '0',
                                height: '2px',
                                backgroundColor: 'black',
                                transition: 'width 0.3s ease-in-out',
                            },
                            '&:hover': {
                                color: 'black'
                            },
                        }}
                    >
                        {letter}
                    </Typography>
                ))}
            </Box>

            <Grid2 container justifyContent="center" spacing={3} sx={{ p: 7 }}>
                {filteredAuthors.map((author) => (
                    <Grid2 item key={author.id} xs={12} sm={6} md={4} >
                        <Typography variant="body1">{author.name}</Typography>
                    </Grid2>
                ))}
            </Grid2>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='outlined' color='black' size='large' sx={{ borderRadius: 0, }}>Load More</Button>
            </Box>

        </Box >
    )
}

export default Authors