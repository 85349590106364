import React from 'react'
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import { ListItem, ListItemButton, List, Box, ListItemIcon, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import VerifiedIcon from '@mui/icons-material/Verified';
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';

const MENU = [
    { name: "Dashboard", path: "/admin/dashboard", icon: <DashboardCustomizeRoundedIcon /> },
    { name: "Books", path: "/admin/books", icon: <ImportContactsRoundedIcon /> },
    { name: "Orders", path: "/admin/allorders", icon: <ShoppingBagRoundedIcon /> },
    { name: "Users", path: "/admin/users", icon: <VerifiedIcon /> },
    { name: "Setting", path: "/admin/setting", icon: <SettingsSuggestRoundedIcon /> },
];

const Sidebar = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <Box>
            <List>
                {MENU?.map((item, index) => (
                    <ListItem key={index} dense>
                        <ListItemButton
                            disableRipple
                            disableGutters
                            selected={pathname === item.path}
                            onClick={() => handleNavigation(item.path)}
                            sx={{
                                px: 1,
                                borderRadius: 1,
                                transition: 'background-color 0.2s ease',
                                border: pathname === item.path ? '1.2px solid' : '1.2px solid transparent',
                                borderColor: pathname === item.path ? 'primary.main' : 'transparent',
                                '&:hover': {
                                    borderColor: 'primary.main',
                                },
                                '&.Mui-selected': {
                                    borderColor: 'primary.main',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        >
                            <ListItemIcon sx={{
                                minWidth: "35px",
                                color: pathname === item.path ? 'primary.main' : '#333333', // Set active color here
                            }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} sx={{
                                color: pathname === item.path ? 'primary.light' : "#101010",
                                fontWeight: 500
                            }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default React.memo(Sidebar);
